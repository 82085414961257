import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container, Row, Col } from 'react-grid-system';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/faq-hero.jpg';
import Follow from '../components/rows/cta/follow';
import Cta from '../components/rows/cta/index';
import Accordion from '../components/elements/accordion';

export default function ProviderFAQ({ data }) {
  return (
    <Layout>
      <Seo title="Healthcare Providers FAQ" />
      <Hero title="Healthcare Providers FAQ" banner={Banner} />
      {data.allStrapiProviderFaq.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Container className="acc-wrap mb-20">
            <Row>
              <Col md={8} sm={12} className="mx-auto">
                <h2 className="text-center">
                  PACE <span className="text-red-300">info</span> &amp;{' '}
                  <span className="text-red-300">tips</span>
                </h2>
              </Col>
            </Row>
            <Accordion data={node.prov_acc} />
          </Container>
        </React.Fragment>
      ))}
      <Cta
        title="Get in touch with PACE <br> Today!"
        button="Learn More"
        href="/contact"
      />
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query ProviderFAQ {
    allStrapiProviderFaq {
      edges {
        node {
          id # id of the node
          prov_acc {
            id
            title
            description
          }
        }
      }
    }
  }
`;
