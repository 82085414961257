import React from 'react';
import { Row, Col } from 'react-grid-system';
import Button from '../../elements/button';
import Doctor from '../../../images/cta/book-appoitment.webp';

export default function BannerRow(props) {
  return (
    <div className="bg-red-700 cta-book w-full my-52 overflow-hidden">
      {!props.title ? (
        <Row className=" flex flex-col-reverse md:flex-row cta-bg">
          <Col
            md={6}
            sm={12}
            className="flex justify-center banner-content mx-auto text-center"
          >
            <img src={Doctor} className="doctor-image" alt="doctor" />
          </Col>
          <Col
            md={6}
            sm={12}
            className="banner-content mx-auto text-center py-24 md:text-left md:py-56"
          >
            <h2 className="pb-10 text-white">
              Get in touch with us <br />
              today to learn more!
            </h2>
            <Button href={'/how-to-book'} link="Learn how to book" />
          </Col>
        </Row>
      ) : (
        <Row className=" flex flex-col-reverse md:flex-row cta-bg">
          <Col
            md={6}
            sm={12}
            className="flex justify-center banner-content mx-auto text-center"
          >
            <img src={Doctor} className="doctor-image" alt="doctor" />
          </Col>
          <Col
            md={6}
            sm={12}
            className="banner-content mx-auto text-center py-24 md:text-left md:py-56"
          >
            <h2
              className="pb-10 text-white"
              dangerouslySetInnerHTML={{ __html: props.title }}
            />
            <Button href={props.href} link={props.button} />
          </Col>
        </Row>
      )}
    </div>
  );
}
