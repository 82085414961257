import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { Social } from '../../theme/header/nav/links'
import Heart from '../../../icons/heart-like.svg'

export default function Follow() {
    return (
        <div>
            <Container>
                <Row>
                    <Col md={8} className="mx-auto text-center pb-52">
                        <Heart className="w-24 mx-auto pb-6" />
                        <h4>Follow us on <span className="text-red-400">social media</span></h4>
                        <p>Follow us on our social media for regular updates &amp; important resources. </p>
                        <div className="social-icons flex justify-between w-4/12 mx-auto pt-10">
                            {Social.map((link, i) => (
                                <a href={link.href} key={i} title={link.name}>
                                    {link.icon}
                                </a>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}